<template>
    <el-dialog :title="title" :visible.sync="visible" :before-close="handleClose">
        <el-table :data="tableData" v-loading="$waiting.is('modalLoading')" height="700">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-table :data="props.row.children" class="ml-12">
                        <el-table-column prop="invoiceNumber" label="Fakturanummer" width="180" />
                        <el-table-column prop="id" label="Faktura-id" width="180" />
                        <el-table-column width="180">
                            <template slot-scope="scope"><i class="far fa-file-pdf cursor-pointer" @click="onClickPdf(scope.row.id)"/></template
                        ></el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label="Kundnamn" prop="name" />
        </el-table>
    </el-dialog>
</template>
<script>
import Api from "../suppliers.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        sourceRow: {
            type: Object,
        },
    },

    watch: {
        sourceRow() {
            this.tableData = [];
            this.title = this.sourceRow.name;
            this.getClientsInfo();
        },
    },

    data() {
        return {
            tableData: [],
            title: "",
        };
    },

    methods: {
        handleClose() {
            this.$emit("close");
        },

        async getClientsInfo() {
            this.$waiting.start("modalLoading");
            this.tableData = await Api.getSupplierClientsInfo(this.sourceRow.id);
            this.$waiting.end("modalLoading");
        },

        onClickPdf(id) {
            Api.showInvoicePdf(id);
        },
    },
};
</script>
